import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';

export function SuccesPageTemplate({ successMessage, html }) {
	return (
		<div className="medium-contain contact-wrapper">
			<div className="contact-from-wrappper">
				{successMessage && <MakeHtml text={successMessage} />}
			</div>
			<div className="contact-markdown-wrapper">
				<h2>Nos coordonnées</h2>
				<div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
			<style jsx>{`
				h1 {
					text-align: center;
				}
				.contact-wrapper {
					min-height: 78vh;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					padding: 10px 0;
				}
				.contact-from-wrappper {
					width: 100%;
					padding: 60px 20px;
					box-sizing: border-box;
				}
				.contact-markdown-wrapper {
					width: 100%;
					padding: 60px 20px;
					box-sizing: border-box;
					text-align: center;
				}

				@media (min-width: 1200px) {
					.contact-wrapper {
						display: flex;
						align-items: flex-start;
						justify-content: space-around;
						flex-direction: row;
					}
					.contact-from-wrappper {
						width: 50%;
					}
					.contact-markdown-wrapper {
						width: auto;
						padding: 60px 20px;
						box-sizing: border-box;
						text-align: left;
					}
				}
			`}</style>
		</div>
	);
}

export default function SuccessPage({ data }) {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<>
			<Helmet
				defer={false}
				defaultTitle={frontmatter.seo.seoTitle}
				titleTemplate={`${frontmatter.seo.seoDescription}`}
			>
				<meta charSet="UTF-8" />
				<meta name="robots" content="noindex" />
			</Helmet>
			<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription}>
				<SuccesPageTemplate successMessage={frontmatter.successMessage} html={html} />
			</Layout>
		</>
	);
}

export const pageQuery = graphql`
	query SuccesPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			html
			frontmatter {
				title
				successMessage
				seo {
					seoDescription
					seoTitle
				}
			}
		}
	}
`;
